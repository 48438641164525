import { UserMutation } from '@/store/mutationTypes';

export default {
  [UserMutation.SET](state, users) {
    state.users = users;
  },
  [UserMutation.REMOVE](state, itemId) {
    const userIndex = state.users.findIndex((u) => u.id === itemId);
    state.users.splice(userIndex, 1);
  },
};
