<template>
  <div id="user-edit-tab-permissions">
    <!-- Content Row -->
    <div class="vx-row pb-10">
      <div class="vx-col md:w-1/4 w-full mt-10">
        <h4>Dashboard</h4>
        <div class="mt-3">
          <label
            v-for="permission in dashboardList"
            :key="permission.value"
            :for="permission.value"
            class="flex align-center mt-2"
          >
            <vs-switch
              :id="permission.value"
              v-model="userPermissions"
              :vs-value="permission.value"
              color="primary"
              class="mr-2"
              :disabled="switchersDisabled"
            />
            {{ permission.label }}
          </label>
        </div>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-10">
        <h4>Campaigns</h4>
        <div class="mt-3">
          <label
            v-for="permission in campaignList"
            :key="permission.value"
            :for="permission.value"
            class="flex align-center mt-2"
          >
            <vs-switch
              :id="permission.value"
              v-model="userPermissions"
              :vs-value="permission.value"
              color="primary"
              class="mr-2"
              :disabled="switchersDisabled"
            />
            {{ permission.label }}
          </label>
        </div>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-10">
        <h4>Products</h4>
        <div class="mt-3">
          <label
            v-for="permission in productList"
            :key="permission.value"
            :for="permission.value"
            class="flex align-center mt-2"
          >
            <vs-switch
              :id="permission.value"
              v-model="userPermissions"
              :vs-value="permission.value"
              color="primary"
              class="mr-2"
              :disabled="switchersDisabled"
            />
            {{ permission.label }}
          </label>
        </div>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-10">
        <h4>Brands</h4>
        <div class="mt-3">
          <label
            v-for="permission in brandList"
            :key="permission.value"
            :for="permission.value"
            class="flex align-center mt-2"
          >
            <vs-switch
              :id="permission.value"
              v-model="userPermissions"
              :vs-value="permission.value"
              color="primary"
              class="mr-2"
              :disabled="switchersDisabled"
            />
            {{ permission.label }}
          </label>
        </div>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-10">
        <h4>Retailers</h4>
        <div class="mt-3">
          <label
            v-for="permission in retailerList"
            :key="permission.value"
            :for="permission.value"
            class="flex align-center mt-2"
          >
            <vs-switch
              :id="permission.value"
              v-model="userPermissions"
              :vs-value="permission.value"
              color="primary"
              class="mr-2"
              :disabled="switchersDisabled"
            />
            {{ permission.label }}
          </label>
        </div>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-10">
        <h4>Product categories</h4>
        <div class="mt-3">
          <label
            v-for="permission in productCategoryList"
            :key="permission.value"
            :for="permission.value"
            class="flex align-center mt-2"
          >
            <vs-switch
              :id="permission.value"
              v-model="userPermissions"
              :vs-value="permission.value"
              color="primary"
              class="mr-2"
              :disabled="switchersDisabled"
            />
            {{ permission.label }}
          </label>
        </div>
      </div>

      <div class="vx-col md:w-1/4 w-full mt-10">
        <h4>Users</h4>
        <div class="mt-3">
          <label
            v-for="permission in userList"
            :key="permission.value"
            :for="permission.value"
            class="flex align-center mt-2"
          >
            <vs-switch
              :id="permission.value"
              v-model="userPermissions"
              :vs-value="permission.value"
              color="primary"
              class="mr-2"
              :disabled="switchersDisabled"
            />
            {{ permission.label }}
          </label>
        </div>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button
            v-if="$gate.can('edit_users')"
            class="ml-auto mt-2"
            @click="save"
            :disabled="!validateForm || saving"
          >
            Save Changes
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { UserAction } from '@/store/actionTypes';

import { UserRole } from '@/enums/UserRole';
import { getPermissionListByCategory } from '@/enums/UserPermission';

import { getMessageFromError } from '@/helpers';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      dataPermissions: JSON.parse(JSON.stringify(this.data)).permission_names,
      saving: false,
    };
  },
  computed: {
    ...mapState({
      activeUser: ({ AppActiveUser }) => AppActiveUser,
    }),

    userPermissions: {
      get() {
        return this.dataPermissions;
      },
      set(obj) {
        this.dataPermissions = obj;
      },
    },
    dashboardList() {
      return getPermissionListByCategory('Dashboard');
    },
    campaignList() {
      return getPermissionListByCategory('Campaigns');
    },
    productList() {
      return getPermissionListByCategory('Products');
    },
    brandList() {
      return getPermissionListByCategory('Brands');
    },
    retailerList() {
      return getPermissionListByCategory('Retailers');
    },
    productCategoryList() {
      return getPermissionListByCategory('Product categories');
    },
    userList() {
      return getPermissionListByCategory('Users');
    },
    userId() {
      return parseInt(this.$route.params.userId);
    },
    validateForm() {
      return !this.errors.any();
    },
    isSysAdmin() {
      return this.dataLocal.role === UserRole.SYSADMIN.value;
    },
    isMyAccount() {
      return this.activeUser.id === this.dataLocal.id;
    },
    switchersDisabled() {
      if (this.isSysAdmin) {
        return false;
      }

      if (!this.isSysAdmin && this.isMyAccount) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapActions('user', [UserAction.updatePermissions]),

    handleChange({ name, val }) {
      let newPermissions = [...this.dataPermissions];

      if (!val) {
        newPermissions.push(name);
      } else {
        newPermissions = this.dataPermissions.filter((el) => el !== name);
      }

      this.dataPermissions = newPermissions;
    },
    async save() {
      if (this.$gate.can('edit_users')) {
        this.$vs.loading();

        try {
          const obj = {
            id: this.userId,
            permissions: this.userPermissions,
          };

          await this.updateUserPermissions(obj);

          this.notifySuccess(
            'User updated',
            'The user has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, getMessageFromError(error));
        }

        this.$vs.loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-profile-img {
  width: 112px;
  height: 112px;
  border-radius: 100%;
}
</style>
