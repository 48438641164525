export const UserStatus = Object.freeze({
  ALL: { label: 'All', value: 'all', color: 'black' },
  DEACTIVATED: { label: 'Deactivated', value: 0, color: 'danger' },
  ACTIVE: { label: 'Active', value: 1, color: 'success' },
  BLOCKED: { label: 'Blocked', value: 2, color: 'warning' },
});

export const userStatusList = [...Object.values(UserStatus)];

export const getStatusByValue = (val = null) => {
  if (val === null) return '';

  return userStatusList.find((el) => el.value === val);
};

export const getStatusName = (val = null) => {
  if (val === null) return '';

  return getStatusByValue(val).label;
};

export const getStatusColor = (val = null) => {
  if (val === null) return '';

  return getStatusByValue(val).color;
};
