import { Region } from '@/enums/Region';

export const Country = Object.freeze({
  AUSTRALIA: { label: 'Australia', value: 'AU', region: Region.APAC.value },
  BELGIUM: { label: 'Belgium', value: 'BE', region: Region.BENELUX.value },
  CANADA: { label: 'Canada', value: 'CA', region: Region.AMERICAS.value },
  CHINA: { label: 'China', value: 'CN', region: Region.CHINA.value },
  CZECH_REPUBLIC: {
    label: 'Emerging markets - Czech Republic',
    value: 'CZ',
    region: Region.EMEA.value,
  },
  DENMARK: {
    label: 'Nordics - Denmark',
    value: 'NORDICS_DK',
    region: Region.NORDIC.value,
  },
  ESTONIA: {
    label: 'Emerging markets - Estonia',
    value: 'EE',
    region: Region.EMEA.value,
  },
  FINLAND: {
    label: 'Nordics - Finland',
    value: 'NORDICS_FI',
    region: Region.NORDIC.value,
  },
  FRANCE: { label: 'France', value: 'FR', region: Region.FRANCE.value },
  GERMANY: { label: 'Germany', value: 'DE', region: Region.GERMANY.value },
  HONG_KONG: { label: 'Hong Kong', value: 'HK', region: Region.APAC.value },
  HUNGARY: {
    label: 'Emerging markets - Hungary',
    value: 'HU',
    region: Region.EMEA.value,
  },
  ICELAND: {
    label: 'Nordics - Iceland',
    value: 'NORDICS_IS',
    region: Region.NORDIC.value,
  },
  INDIA: { label: 'India', value: 'IN', region: Region.APAC.value },
  IRELAND: { label: 'Ireland', value: 'IE', region: Region.EMEA.value },
  ISRAEL: { label: 'Israel', value: 'IL', region: Region.EMEA.value },
  JAPAN: { label: 'Japan', value: 'JP', region: Region.APAC.value },
  KOREA: {
    label: 'Korea, Republic of',
    value: 'KR',
    region: Region.APAC.value,
  },
  LATVIA: {
    label: 'Emerging markets - Latvia',
    value: 'LV',
    region: Region.EMEA.value,
  },
  LITHUANIA: {
    label: 'Emerging markets - Lithuania',
    value: 'LT',
    region: Region.EMEA.value,
  },
  MEXICO: { label: 'Mexico', value: 'MX', region: Region.AMERICAS.value },
  NETHERLANDS: {
    label: 'Netherlands',
    value: 'NL',
    region: Region.BENELUX.value,
  },
  NEW_ZELAND: { label: 'New Zealand', value: 'NZ', region: Region.APAC.value },
  NORDICS: {
    label: 'Nordics - All countries',
    value: 'NORDICS',
    region: Region.NORDIC.value,
  },
  NORWAY: {
    label: 'Nordics - Norway',
    value: 'NORDICS_NO',
    region: Region.NORDIC.value,
  },
  POLAND: {
    label: 'Emerging markets - Poland',
    value: 'PL',
    region: Region.EMEA.value,
  },
  PORTUGAL: { label: 'Portugal', value: 'PT', region: Region.EMEA.value },
  RUSSIAN_FEDERATION: {
    label: 'Emerging markets - Russian Federation',
    value: 'RU',
    region: Region.RUSSIA.value,
  },
  SINGAPORE: { label: 'Singapore', value: 'SG', region: Region.APAC.value },
  SLOVAKIA: {
    label: 'Emerging markets - Slovakia',
    value: 'SK',
    region: Region.EMEA.value,
  },
  SPAIN: { label: 'Spain', value: 'ES', region: Region.EMEA.value },
  SWEDEN: {
    label: 'Nordics - Sweden',
    value: 'NORDICS_SE',
    region: Region.NORDIC.value,
  },
  SWITZERLAND: { label: 'Switzerland', value: 'CH', region: Region.EMEA.value },
  UK: {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GB',
    region: Region.UK.value,
  },
  US: {
    label: 'United States of America',
    value: 'US',
    region: Region.US.value,
  },
});

export const countryList = [...Object.values(Country)];

export const getCountryByValue = (val = null) => {
  if (val === null) return '';

  return countryList.find((el) => el.value === val);
};

export const getCountryName = (val) => {
  if (val === null) return '';

  return getCountryByValue(val).label;
};
