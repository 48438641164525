import axios from '@/axios';
import { UserAction } from '@/store/actionTypes';
import { UserMutation } from '@/store/mutationTypes';

export default {
  [UserAction.fetch]({ commit }) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/users`)
        .then(({ data }) => {
          commit(UserMutation.SET, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [UserAction.fetchOne](context, userId) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/users/${userId}`)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [UserAction.add](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/users`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [UserAction.update](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/users/${item.id}`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
  [UserAction.remove]({ commit }, userId) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/users/${userId}`)
        .then(({ data }) => {
          commit(UserMutation.REMOVE, userId);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [UserAction.updatePermissions](context, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/users/${item.id}/permissions`, item)
        .then(({ data }) => resolve(data))
        .catch(reject);
    });
  },
};
