<template>
  <div id="user-edit-tab-account">
    <!-- Avatar Row -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="flex items-start flex-col sm:flex-row">
          <img class="user-profile-img" :src="dataLocal.photo" />
        </div>
      </div>
    </div>

    <!-- Content Row -->
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <vs-label text="Name" />
          <vs-input
            class="w-full"
            v-model="dataLocal.name"
            v-validate="'required|alpha_spaces'"
            name="name"
          />
          <span class="text-danger text-sm" v-show="errors.has('name')">
            {{ errors.first('name') }}
          </span>
        </div>

        <div class="mt-4">
          <vs-label text="Email" />
          <vs-input
            class="w-full"
            v-model="dataLocal.email"
            type="email"
            v-validate="'required|email'"
            name="email"
          />
          <span class="text-danger text-sm" v-show="errors.has('email')">
            {{ errors.first('email') }}
          </span>
        </div>

        <div class="mt-4">
          <vs-label text="Mobile" />
          <vs-input class="w-full" v-model="dataLocal.mobile" name="mobile" />
        </div>

        <div class="mt-4">
          <vs-label text="Country" />
          <v-select
            v-model="countryLocal"
            :clearable="false"
            :options="countryList"
            v-validate="'required'"
            name="country"
          />
          <span class="text-danger text-sm" v-show="errors.has('country')">
            {{ errors.first('country') }}
          </span>
        </div>
      </div>

      <div class="vx-col md:w-1/2 w-full">
        <div class="mt-4">
          <vs-label text="Status" />
          <v-select
            v-model="statusLocal"
            :clearable="false"
            :options="userStatusList"
            v-validate="'required'"
            name="status"
          />
          <span class="text-danger text-sm" v-show="errors.has('status')">
            {{ errors.first('status') }}
          </span>
        </div>

        <div class="mt-4">
          <vs-label text="Role" />
          <v-select
            v-model="roleLocal"
            :clearable="false"
            :options="userRoleList"
            v-validate="'required'"
            name="role"
          />
          <span class="text-danger text-sm" v-show="errors.has('role')">
            {{ errors.first('role') }}
          </span>
        </div>

        <div class="mt-4">
          <vs-label text="Role" />
          <v-select
            v-model="regionLocal"
            :clearable="false"
            :options="regionList"
            name="region"
          />
        </div>
      </div>
    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button
            v-if="$gate.can('edit_users')"
            class="ml-auto mt-2"
            @click="save"
            :disabled="!validateForm"
          >
            Save Changes
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions } from 'vuex';

import { UserAction } from '@/store/actionTypes';

import userTabAccountMixin from '@/mixins/userTabAccountMixin';

import { getMessageFromError } from '@/helpers';

export default {
  mixins: [userTabAccountMixin],
  components: {
    vSelect,
  },
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
    };
  },
  computed: {
    countryLocal: {
      get() {
        return this.countryList.find(
          (item) => item.value === this.dataLocal.country
        );
      },
      set(obj) {
        this.dataLocal.country = obj.value;
      },
    },
    statusLocal: {
      get() {
        return this.userStatusList.find(
          (item) => item.value === this.dataLocal.status
        );
      },
      set(obj) {
        this.dataLocal.status = obj.value;
      },
    },
    roleLocal: {
      get() {
        return this.userRoleList.find(
          (item) => item.value === this.dataLocal.role
        );
      },
      set(obj) {
        this.dataLocal.role = obj.value;
      },
    },
    regionLocal: {
      get() {
        return this.regionList.find(
          (item) => item.value === this.dataLocal.region
        );
      },
      set(obj) {
        this.dataLocal.region = obj.value;
      },
    },
    userId() {
      return parseInt(this.$route.params.userId);
    },
    validateForm() {
      return (
        !this.errors.any() &&
        this.dataLocal.name !== null &&
        this.dataLocal.name !== '' &&
        this.dataLocal.email !== null &&
        this.dataLocal.email !== '' &&
        this.dataLocal.country !== null &&
        this.dataLocal.role !== null
      );
    },
  },
  methods: {
    ...mapActions('user', [UserAction.update]),

    async save() {
      if (this.$gate.can('edit_users')) {
        this.$vs.loading();

        try {
          const {
            name,
            email,
            mobile,
            status,
            country,
            role,
            region,
          } = this.dataLocal;

          const obj = {
            id: this.userId,
            name,
            email,
            mobile,
            status,
            country,
            role,
            region,
          };

          await this.updateUser(obj);

          this.notifySuccess(
            'User updated',
            'The user has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, getMessageFromError(error));
        }

        this.$vs.loading.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-profile-img {
  width: 112px;
  height: 112px;
  border-radius: 100%;
}
</style>
